import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { UnAuthGuard } from './guards/unauth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./components/homepage-mobile/homepage-mobile.module').then(
        (m) => m.HomepageMobileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
    canActivate: [UnAuthGuard],
  },
  {
    path: 'user/:id',
    loadChildren: () =>
      import('./components/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'userInfo/:id',
    loadChildren: () =>
      import('./user-info/user-info.module').then((m) => m.UserInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
